<template>
  <section class="all-documents-view">
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false"
      :title="signup._id == undefined ? 'Invite User' : 'Edit User'" :visible.sync="documentActionModal"
      :width="getIsMobile ? '100%' : signup._id == undefined ? '30%' : '60%'" class="doc-modal"
      v-loading.fullscreen.lock="dLoading" element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)" :before-close="resetData">
      <el-form :model="signup" :rules="rules" ref="companyUser" class="log-form">
        <template v-if="signup._id">
          <el-row>
            <template>
              <el-col class="el-col-11 mt-1" :span="11">
                <el-form-item label="First Name" prop="first_name" v-if="isEdit">
                  <template>
                    <el-input v-model="signup.first_name" placeholder="Enter first name"></el-input>
                    <p class="error" v-if="getErrors && getErrors.first_name">
                      {{ getErrors.first_name }}
                    </p>
                  </template>
                </el-form-item>
                <template v-if="!isEdit">
                  <span class="text-bold">First Name</span>
                  <p v-if="!isEdit">{{ signup.first_name }}</p>
                </template>
              </el-col>
              <el-col class="el-col-11 el-col-offset-1 mt-1" :span="11">
                <el-form-item label="Last Name" prop="last_name" v-if="isEdit">
                  <template>
                    <el-input v-model="signup.last_name" placeholder="Enter last name"></el-input>
                    <p class="error" v-if="getErrors && getErrors.last_name">
                      {{ getErrors.last_name }}
                    </p>
                  </template>
                </el-form-item>
                <template v-if="!isEdit">
                  <span class="text-bold">Last Name</span>
                  <p>{{ signup.last_name }}</p>
                </template>
              </el-col>
            </template>
          </el-row>
          <el-row>
            <template>
              <el-col class="el-col-11 mt-1" :span="11">
                <el-form-item label="Mobile" prop="phone" v-if="isEdit">
                  <template>
                    <el-input type="number" v-model="signup.phone" placeholder="989 989 9899"
                      autocomplete="off"></el-input>
                    <p class="error" v-if="getErrors && getErrors.phone">
                      {{ getErrors.phone }}
                    </p>
                  </template>
                </el-form-item>
                <template v-if="!isEdit">
                  <span class="text-bold">Phone</span>
                  <p>{{ signup.phone }}</p>
                </template>
              </el-col>
              <el-col class="el-col-11 el-col-offset-1 mt-1" :span="11">
                <el-form-item label="Email Address" prop="email" v-if="isEdit">
                  <template>
                    <el-input type="email" v-model="signup.email" @blur="verifyDomainUser"
                      placeholder="Ex: johnwesley@abc.com" autocomplete="off" readonly :disabled="true"></el-input>
                    <p class="error" v-if="getErrors && getErrors.email">
                      {{ getErrors.email }}
                    </p>
                    <p class="error" v-if="
                      getErrors &&
                      getErrors.critical_error &&
                      getErrors.critical_error ==
                      'Account with this email is already taken'
                    ">
                      {{ getErrors.critical_error }}
                    </p>
                    <p class="error" v-if="isNotValidEmail">
                      Only Users from {{ brandingInfo.email_domain }} are
                      allowed
                    </p>
                  </template>
                </el-form-item>
                <template v-if="!isEdit">
                  <span class="text-bold">Email Address</span>
                  <p>{{ signup.email }}</p>
                </template>
              </el-col>
            </template>
          </el-row>
          <el-row>
            <template>
              <el-col class="el-col-11 mt-1" :span="11">
                <el-form-item label="Roles" prop="user_type" v-if="isEdit">
                  <el-select class="el-col-24" v-model="signup.selectedRoles" placeholder="Select Role" multiple
                    collapse-tags>
                    <el-option v-for="(type, index) of userType" :key="index" :label="getLabel(type)"
                      :value="type._id"></el-option>
                  </el-select>
                  <p class="error" v-if="getErrors && getErrors.user_type">
                    {{ getErrors.user_type }}
                  </p>
                </el-form-item>
                <template v-if="!isEdit">
                  <span class="text-bold">Roles</span>
                  <br />
                  <!-- <p>{{ getLabel(signup.user_type) }}</p> -->
                  <div v-for="role in signup.roles_list" :key="'user' + role.id">
                    <el-popover trigger="hover">
                      <div style="display: flex; flex-direction: column">
                        <p style="font-weight: bold" v-if="role && role.userType && role.userType.name">
                          {{ role.userType.name }}
                        </p>
                      </div>
                      <el-tag size="small" :type="getColor(role.userType)" effect="dark" slot="reference">
                        <span v-if="getColor(role.userType) === 'danger'">OWNER</span>
                        <span v-else>{{ role.role_id.title }}</span>
                      </el-tag>
                    </el-popover>
                  </div>
                  <!-- <el-popover trigger="hover">
                      <div style="display: flex; flex-direction: column">
                        <p
                          v-for="role in signup.roles_list" 
                          :key="'user' + role.id"
                          style="font-weight: bold" 
                        >{{ `${role.userType.name}` }}</p> 
                      </div>
                      <el-tag size="small" :type="getColor(role.userType)" effect="dark"> 
                        <span v-if="getColor(role.userType)==='danger'">OWNER</span> 
                        <span v-else>{{ role.role_id.title}}</span> 
                      </el-tag>
                    </el-popover>  -->
                </template>
              </el-col>
            </template>
          </el-row>
        </template>
        <template v-if="signup._id == undefined">
          <el-row>
            <template>
              <el-col class="el-col-24" :span="11">
                <el-form-item label="Email Address" prop="email">
                  <el-input type="email" v-model="signup.email" @blur="verifyDomainUser"
                    placeholder="Ex: johnwesley@abc.com" autocomplete="off"></el-input>
                  <p class="error" v-if="getErrors && getErrors.email">
                    {{ getErrors.email }}
                  </p>
                  <p class="error" v-if="
                    getErrors &&
                    getErrors.critical_error &&
                    getErrors.critical_error ==
                    'Account with this email is already taken'
                  ">
                    {{ getErrors.critical_error }}
                  </p>
                  <p class="error" v-if="isNotValidEmail">
                    Only Users from {{ brandingInfo.email_domain }} are allowed
                  </p>
                </el-form-item>
              </el-col>
              <!-- <el-col class="el-col-24" :span="11">
                  <el-form-item label="User Type" prop="user_type">
                    <el-select class="el-col-24" v-model="signup.user_type_id" placeholder="User Type">
                      <el-option v-for="(type, index) of userType" :key="index" :label="getLabel(type)" :value="type._id">
                      </el-option>
                    </el-select>
                    <p class="error" v-if="getErrors && getErrors.user_type">
                      {{ getErrors.user_type }}
                    </p>
                  </el-form-item>
                </el-col>-->
              <el-col class="el-col-24" :span="11">
                <el-form-item label="Roles" prop="user_type">
                  <el-select class="el-col-24" v-model="signup.selectedRoles" placeholder="Select Role" multiple
                    collapse-tags>
                    <el-option v-for="(type, index) of userType" :key="index" :label="getLabel(type)"
                      :value="type._id"></el-option>
                  </el-select>
                  <p class="error" v-if="getErrors && getErrors.user_type">
                    {{ getErrors.user_type }}
                  </p>
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">
          {{ isEdit ? "Cancel" : "Close" }}
        </el-button>
        <el-button :disabled="
          isEdit
            ? !signup.first_name.trim() || !signup.last_name.trim()
            : (getErrors &&
              (getErrors.email ||
                getErrors.user_type ||
                isNotValidEmail)) ||
            !(signup.selectedRoles && signup.selectedRoles.length)
        " type="primary" @click="submitForm()" v-if="isEdit || signup._id == undefined">Confirm</el-button>
      </span>
    </el-dialog>
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <div class="
              mb-4
              justify-content-between
              align-items-center
              top-search-condition-bar-type-two
            ">
          <h3 class="title fw-normal text-dark mb-0 ls-05">Company Users</h3>
          
          <div class="actions-wrapper contacts-page">
            
            
            <label :data-state="state" for="search">
              <input v-model="search_string" type="text" placeholder="Search by Email" @click="state = 'opan'" @blur="state = 'close'" />
              <i style="font-size: 120%; font-weight: bold; border: 2px" class="el-icon-search"></i>
            </label>
            &nbsp;&nbsp;
            <template v-if="checkPermissionByPermissionName('inviteUser')">
              <el-button type="plain" class="create-btn px-4 ml-2" @click="openDialog">
                <i class="el-icon-position"></i>
                Invite User
              </el-button>&nbsp;&nbsp;
            </template>
            
            <el-dropdown class="import-dropdown-btn">
              <!-- {{ checkPermissionByPermissionName('deleteUser') }} -->
              <el-button size="mini" class="el-dropdown-link px-2">
                Actions 
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu>
                <a @click="bulkdelete()" v-if="checkPermissionByPermissionName('deleteUser')">
                  <el-dropdown-item >
                    <i class="el-icon-delete"></i>
                    Delete
                  </el-dropdown-item>
                </a>
                <el-dropdown-item v-else disabled>
                  <i class="el-icon-delete"></i>
                  Delete
                </el-dropdown-item>
                
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div v-loading.fullscreen.lock="loading" class="vue-data-table-default" :element-loading-text="loadingText">
          <data-tables-server v-loading="configureLoading" :fullscreen="true" :data="data" :total="total"
            :current-page.sync="currentPage" :page-size="pageSize" :pagination-props="paginationProps"
            @query-change="loadData" @sort-change="sortChange" style="width: 100%"
            @selection-change="handleSelectionChange" v-if="!getIsMobile">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="Name" id="name" prop="first_name" min-width="180" sortable="custom" fixed="left">
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                <el-popover trigger="hover" placement="top" >
                 <div slot="reference" class="icon-text">{{ scope.row | getUserFullName  | truncate(10, "...")}}</div>
                 <div style="display: flex; flex-direction: column">
                 <p>{{ scope.row | getUserFullName }}</p>
                 </div>
                </el-popover>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column :default-sort="{ prop: 'user_type.name', order: 'descending' }" prop="user_type.name"
                label="Role" sortable width="150">
                <template slot-scope="scope">{{
                getData(scope, "user_type")
                }}</template>
              </el-table-column>-->
            <el-table-column label="Roles" width="150">
              <template slot-scope="scope">
                <div v-for="role in scope.row.rolesList" :key="'user' + role.id">
                  <el-popover trigger="hover">
                    <div style="display: flex; flex-direction: column">
                      <p style="font-weight: bold" v-if="role && role.userType && role.userType.name">
                        {{ role.userType.name }}
                      </p>
                    </div>
                    <el-tag size="small" :type="getColor(role.userType)" effect="dark" slot="reference">
                      <span v-if="getColor(role.userType) === 'danger'">OWNER</span>
                      <span v-else>{{ role.role_id.title }}</span>
                    </el-tag>
                  </el-popover>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="email" label="Email" sortable="custom" width="280"></el-table-column>
            <el-table-column :default-sort="{ prop: 'phone', order: 'descending' }" prop="phone" label="Phone" sortable
              width="150"></el-table-column>
            <el-table-column prop="status" label="Status" sortable width="200">
              <!-- <template slot-scope="scope">
                {{ getData(scope, "status") }}
              </template> -->
            </el-table-column>
            <el-table-column prop="updated_at" label="Last Modified" width="200" sortable :sort-method="updatedDateSort">
              <template slot-scope="scope">{{
                scope.row.updated_at | globalDateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="created_at" label="Invited At" width="200" sortable :sort-method="updatedDateSort">
              <template slot-scope="scope" v-if="getInvitedAt(scope.row)">{{
                getInvitedAt(scope.row) | globalDateFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="action" label="Actions" class="action" align="center" width="200" fixed="right">
              <template slot-scope="scope">
                <ul class="action-buttons" align="center">
                  <li v-if="getData(scope, 'user_type') != 'OWNER'">
                    <el-button plain size="mini" type="primary" class="px-2 ml-2" title="Resend Verification"
                      @click="resendVerificationMail(scope.row.email)" v-if="checkPermissionByPermissionName('inviteUser')&&(
                        getData(scope, 'status') == 'HOLD' ||
                        getData(scope, 'status') == 'INACTIVE')
                      ">
                      <i class="el-icon-message"></i>
                    </el-button>
                  </li>
                  <template v-if="getUserLevelPermission(scope)">
                    <li v-if="checkPermissionByPermissionName('editUser')">
                      <el-button plain type="warning" size="mini" class="px-2 ml-2"
                        @click="onEdit(scope.row, scope.$index)" title="Edit Contact">
                        <i class="el-icon-edit"></i>
                      </el-button>
                    </li>
                    <li v-if="checkPermissionByPermissionName('deleteUser')">
                      <el-button plain type="danger" size="mini" v-if="
                        getData(scope, 'status') == 'HOLD' ||
                        getData(scope, 'status') == 'ACTIVE'
                      " class="px-2 ml-2" @click="onDelete(scope.row._id)" title="Delete Contact">
                        <i class="el-icon-delete"></i>
                      </el-button>
                      <el-button v-else-if="
                        getData(scope, 'status') == 'INACTIVE'
                      " plain type="danger" size="mini" class="px-2 ml-2" @click="onPermanentDelete(scope.row._id)"
                        title="Permanent Delete Contact">
                        <i class="el-icon-delete-solid"></i>
                      </el-button>
                    </li>


                    <li v-if="getData(scope, 'user_type') == 'OWNER'">
                      <el-button plain type="danger" size="mini" class="px-2 ml-2"
                        @click="onView(scope.row, scope.$index)">
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                  </template>
                  <template v-else>
                    <li>
                      <el-button plain type="danger" size="mini" class="px-2 ml-2"
                        @click="onView(scope.row, scope.$index)">
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                  </template>

                  <!-- <template >
                      <li v-if="getData(scope, 'user_type') == 'OWNER'">
                        <el-button
                          plain
                          type="danger"
                          size="mini"
                          class="px-2 ml-2"
                          @click="onView(scope.row, scope.$index)"
                        >
                          <i class="el-icon-view"></i>
                        </el-button>
                      </li>
                    </template>-->
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>

          <data-tables-server v-loading="configureLoading" :fullscreen="true" :data="data" :total="total"
            :current-page.sync="currentPage" :page-size="pageSize" :pagination-props="paginationProps"
            @query-change="loadData" style="width: 100%" v-if="getIsMobile"  @selection-change="handleSelectionChange" class="contacts-list-table">
            <el-table-column type="expand"> 
              <template slot-scope="scope">
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Status:</div>
                  <div class="expand-column-item-content">
                    {{ getData(scope, "status") }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Email:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.email }}
                  </div>
                </div>
                <div class="expand-column-item">
                  <div class="expand-column-item-label">Phone:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.phone }}
                  </div>
                </div>
                <!-- <div class="expand-column-item">
                    <div class="expand-column-item-label">Role:</div>
                    <div class="expand-column-item-content">
                      {{ getData(scope, "user_type") }}
                    </div>
                  </div>-->

                <div class="expand-column-item">
                  <div class="expand-column-item-label">Roles:</div>
                  <div class="expand-column-item-content">
                    <el-tag size="small" v-for="(role, index) in scope.row.rolesList" :key="index"
                      :type="getColor(role.userType)" effect="dark">
                      <span v-if="getColor(role.userType) === 'danger'">OWNER</span>
                      <span v-else>{{ role.role_id.title }}</span>
                    </el-tag>
                  </div>
                </div>

                <div class="expand-column-item">
                  <div class="expand-column-item-label">Last Modified:</div>
                  <div class="expand-column-item-content">
                    {{ scope.row.updated_at }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Name" id="name" fixed>
              <template slot-scope="scope">
                <div class="icon-block initial">
                  <div class="icon">
                    <div class="initial-text">
                      <span>{{ scope.row | getUserNameAvatar }}</span>
                    </div>
                  </div>
                  <div class="icon-text">{{ scope.row | getUserFullName }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="action" label="Actions" class="action" align="right" fixed>
              <template slot-scope="scope">
                <ul class="action-buttons" style="flex-direction: column">
                  <li style="margin-right: 2px;margin-top: 2px;margin-left: -22px;">
                    <!--  -->
                    <el-button plain size="mini" type="primary" class="px-2 ml-2" title="Resend Verification"
                      @click="resendVerificationMail(scope.row.email)" v-if="checkPermissionByPermissionName('inviteUser')&&(
                        getData(scope, 'status') == 'HOLD' ||
                        getData(scope, 'status') == 'INACTIVE')
                      ">
                      <i class="el-icon-message"></i>
                    </el-button>

                    <template v-if="getUserLevelPermission(scope)">
                      <el-button v-if="checkPermissionByPermissionName('editUser')" plain type="warning" size="mini"
                        class="px-2" @click="onEdit(scope.row, scope.$index)">
                        <i class="el-icon-edit"></i>
                      </el-button>
                      <el-button v-if="checkPermissionByPermissionName('deleteUser')" plain type="danger" size="mini"
                        class="px-2" @click="onDelete(scope.row._id)">
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </template>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
    <el-dialog class="DialogueStyle" title="Warning" :visible.sync="centerDialogVisible" width="25%"
      :close-on-click-modal="false" :close-on-press-escape="false">
      <span v-if="this.selected_CompanyUser.length == 1">Are you sure you want to delete 1 selected company user?</span>
      <span v-if="
        this.selected_CompanyUser.length == 0 ||
        this.selected_CompanyUser.length > 1
      ">Are you sure you want to delete
        {{ this.selected_CompanyUser.length }} selected company users?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="selectedCompanyUserDelete()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- result popup of Importing contacts -->
    <el-dialog :close-on-click-model="false" :close-on-press-escape="false"
      :visible.sync="viewImportingContactsResultPopup" class="outersize-confirm-type-three importing-result-popup"
      title="Importing Result" width="100%">
      <el-row :gutter="30">
        <el-col :lg="{ span: 23, offset: 0 }">
          <div class="result-row">
            <span class="title bold larger-font-size">Total Contacts in your Gmail account:</span>
            <span class="value">{{ importTotalContactsCnt }}</span>
          </div>
          <div class="result-row indent">
            <span class="title">Contacts added:</span>
            <span class="value">{{ importAddedContactsCnt }}</span>
          </div>
          <div class="result-row indent">
            <span class="title">Contacts updated:</span>
            <span class="value">{{ importUpdatedContactsCnt }}</span>
          </div>
          <div class="result-row failed indent">
            <span class="title">Contacts failed:</span>
            <span class="value">{{ importFailedContactsCnt }}</span>
          </div>
          <!-- <div class="result-row error-details-title">
              <span class="title bold larger-font-size"> Error Details: </span>
            </div>-->
          <div class="error-details indent" v-for="item in importErrorDetails" :key="item" :value="item">
            {{ item }}
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog :close-on-click-model="false" :close-on-press-escape="false" title="Import Contacts From Gmail"
      :visible.sync="showImportContactsPopup" :width="getIsMobile ? '100%' : '60%'"
      class="doc-modal import-contacts-popup">
      <!-- :before-close="resetBulkModal" -->
      <div>
        <el-tabs v-model="activeNameForImport">
          <el-tab-pane :label="`All Contacts ${successfullCSVData.length}`" name="success">
            <el-scrollbar wrap-style>
              <div class="import-contacts-table-wrapper">
                <el-table :data="getCurrentPageOfSuccessfullCSVData" style="width: 100%"
                  @cell-mouse-enter="editSuccessCSVData" @cell-mouse-leave="resetSuccessCSVData" row-key="id"
                  class="import-contacts-table">
                  <el-table-column v-for="(row, index) in primaryHeaders" :key="index" :label="row" width="220">
                    <template slot-scope="scope">
                      <div v-if="currentActiveSuccessRowIndex == scope.row['id']">
                        <el-input size="mini" v-model="scope.row[row]" v-if="row != 'user_type'"></el-input>
                        <el-select v-model="scope.row[row]" placeholder="Select contact type" filterable
                          default-first-option class="filter-user-type" v-if="row == 'user_type'">
                          <el-option v-for="(type, index) in userType" :key="index + 1" :value="type.name"
                            :label="type.name">{{ type.name }}</el-option>
                        </el-select>
                      </div>
                      <div v-else>{{ scope.row[row] }}</div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination :current-page.sync="currentPageForIC" :page-sizes="paginationPropsForIC"
              :page-size="pageSizeForIC" layout="prev, pager, next, jumper, total" :total="totalForIC"></el-pagination>
            <!-- layout="prev, pager, next, jumper, sizes, total" -->
          </el-tab-pane>
          <el-tab-pane :label="`Errors ${errorfullCSVData.length}`" name="error">
            <el-scrollbar wrap-style>
              <div class="import-contacts-table-wrapper">
                <el-table :data="getCurrentPageOfErrorfullCSVData" style="width: 100%"
                  @cell-mouse-enter="editErrorCSVData" @cell-mouse-leave="resetErrorCSVData" row-key="id"
                  class="import-contacts-table">
                  <el-table-column v-for="(row, index) in primaryHeaders" :key="index" :label="row" width="220">
                    <template slot-scope="scope">
                      <div v-if="currentActiveRowIndex == scope.row['id']">
                        <el-input size="mini" v-model="scope.row[row]" v-if="row != 'user_type'"></el-input>
                        <el-select v-model="scope.row[row]" placeholder="Select contact type" filterable
                          default-first-option class="filter-user-type" v-if="row == 'user_type'">
                          <el-option v-for="(type, index) in userType" :key="index + 1" :value="type.name"
                            :label="type.name">{{ type.name }}</el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <p v-if="
                          scope.row[row] &&
                          scope.row[row].length &&
                          row == 'phone' &&
                          !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                            scope.row[row]
                          ) &&
                          scope.row[row].length >= 3
                        " style="color: red">
                          Invalid phone
                        </p>
                        <p v-else-if="
                          scope.row[row] &&
                          scope.row[row].length &&
                          row == 'company'
                        ">
                          {{ scope.row[row] }}
                        </p>
                        <p v-else-if="
                          scope.row[row] &&
                          scope.row[row].length &&
                          (row == 'first_name' || row == 'last_name') &&
                          scope.row[row].length < 1
                        " style="color: red">
                          Requires At least 3 letters
                        </p>
                        <p v-else-if="
                          scope.row[row] &&
                          scope.row[row].length &&
                          row == 'email' &&
                          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                            scope.row[row]
                          ) &&
                          scope.row[row].length >= 3
                        " style="color: red">
                          Invalid email
                        </p>
                        <p v-else-if="scope.row[row] && scope.row[row].length">
                          {{ scope.row[row] }}
                        </p>
                        <p v-else-if="
                          row == 'first_name' ||
                          row == 'last_name' ||
                          row == 'email'
                        " style="color: red">
                          Missing Value
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
            <el-pagination :current-page.sync="currentPageForICError" :page-sizes="paginationPropsForICError"
              :page-size="pageSizeForICError" layout="prev, pager, next, jumper, total"
              :total="totalForICError"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" v-if="uploadedCSVDataResult.length">
        <!-- <el-button @click="resetCSVData">Re-Upload CSV</el-button> -->
        <el-button type="danger" :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts">Import Contacts</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-model="false" :close-on-press-escape="false" title="Import Contacts from Spreadsheet"
      :visible.sync="importFromSSActionModal" :width="getIsMobile ? '100%' : '60%'" class="doc-modal"
      :before-close="resetBulkModal">
      <div>
        <el-steps :space="300" :active="activeStep" finish-status="success" align-center
          v-if="getBulkSendPercentage != 100 && !isContactsCreated" class="bulk-step">
          <el-step title="Create CSV"></el-step>
          <el-step title="Upload your CSV"></el-step>
        </el-steps>
        <el-divider></el-divider>
        <div v-if="activeStep == 0">
          <div style="display: flex">
            <p>
              Here is a CSV format to use. Download it and fill with your data.
            </p>
            <el-button size="mini" @click="generateCSVFile" class="download-csv ml-3">Download Blank CSV</el-button>
          </div>
        </div>
        <div v-if="activeStep == 1" style="text-align: center">
          <div v-if="!uploadedCSVDataResult.length">
            <el-upload class="upload-csv-file" drag name="logo" :on-change="uploadBulkSendCSV" action
              :show-file-list="false" :auto-upload="false" accept=".csv">
              <div class="upload-file">
                <div class="mt-2">
                  <img src="@/assets/img/icons/upload-file.svg" alt="Upload File" height="30" />
                </div>
                <p>Drag &amp; Drop CSV file here</p>
                <el-button size="mini"
                  style="background-color: transparent;border-color: #f754a2;color: #f754a2;border-radius: 0;">Import
                  Contacts List</el-button>
              </div>
            </el-upload>
          </div>
          <div v-else>
            <el-tabs v-model="activeNameForImport">
              <el-tab-pane :label="`All Contacts ${successfullCSVData.length}`" name="success">
                <el-scrollbar wrap-style>
                  <div class="import-contacts-table-wrapper">
                    <el-table :data="getCurrentPageOfSuccessfullCSVData" style="width: 100%"
                      @cell-mouse-enter="editSuccessCSVData" @cell-mouse-leave="resetSuccessCSVData" row-key="id"
                      class="import-contacts-table">
                      <el-table-column v-for="(row, index) in primaryHeaders" :key="index" :label="row" width="220">
                        <template slot-scope="scope">
                          <div v-if="
                            currentActiveSuccessRowIndex == scope.row['id']
                          ">
                            <el-input size="mini" v-model="scope.row[row]" v-if="row != 'user_type'"></el-input>
                            <el-select v-model="scope.row[row]" placeholder="Select contact type" filterable
                              default-first-option class="filter-user-type" v-if="row == 'user_type'">
                              <el-option v-for="(type, index) in userType" :key="index + 1" :value="type.name"
                                :label="type.name">{{ type.name }}</el-option>
                            </el-select>
                          </div>
                          <div v-else>{{ scope.row[row] }}</div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <el-pagination @size-change="handleSizeChangeForIC" @current-change="handleCurrentChangeForIC"
                  :current-page.sync="currentPageForIC" :page-sizes="paginationPropsForIC" :page-size="pageSizeForIC"
                  layout="prev, pager, next, jumper, total" :total="totalForIC"></el-pagination>
                <!-- layout="prev, pager, next, jumper, sizes, total" -->
              </el-tab-pane>
              <el-tab-pane :label="`Errors ${errorfullCSVData.length}`" name="error">
                <el-scrollbar wrap-style>
                  <div class="import-contacts-table-wrapper">
                    <el-table :data="getCurrentPageOfErrorfullCSVData" style="width: 100%"
                      @cell-mouse-enter="editErrorCSVData" @cell-mouse-leave="resetErrorCSVData" row-key="id"
                      class="import-contacts-table">
                      <el-table-column v-for="(row, index) in primaryHeaders" :key="index" :label="row" width="220">
                        <template slot-scope="scope">
                          <div v-if="currentActiveRowIndex == scope.row['id']">
                            <el-input size="mini" v-model="scope.row[row]" v-if="row != 'user_type'"></el-input>
                            <el-select v-model="scope.row[row]" placeholder="Select contact type" filterable
                              default-first-option class="filter-user-type" v-if="row == 'user_type'">
                              <el-option v-for="(type, index) in userType" :key="index + 1" :value="type.name"
                                :label="getLabel(type)">{{ type.name }}</el-option>
                            </el-select>
                          </div>
                          <div v-else>
                            <p v-if="
                              scope.row[row] &&
                              scope.row[row].length &&
                              row == 'phone' &&
                              !/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/.test(
                                scope.row[row]
                              ) &&
                              scope.row[row].length >= 3
                            " style="color: red">
                              Invalid phone
                            </p>
                            <p v-else-if="
                              scope.row[row] &&
                              scope.row[row].length &&
                              row == 'company'
                            ">
                              {{ scope.row[row] }}
                            </p>
                            <p v-else-if="
                              scope.row[row] &&
                              scope.row[row].length &&
                              (row == 'first_name' || row == 'last_name') &&
                              scope.row[row].length < 1
                            " style="color: red">
                              Requires At least 3 letters
                            </p>
                            <p v-else-if="
                              scope.row[row] &&
                              scope.row[row].length &&
                              row == 'email' &&
                              !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                                scope.row[row]
                              ) &&
                              scope.row[row].length >= 3
                            " style="color: red">
                              Invalid email
                            </p>
                            <p v-else-if="
                              scope.row[row] && scope.row[row].length
                            ">
                              {{ scope.row[row] }}
                            </p>
                            <p v-else-if="
                              row == 'first_name' ||
                              row == 'last_name' ||
                              row == 'email'
                            " style="color: red">
                              Missing Value
                            </p>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-scrollbar>
                <el-pagination @size-change="handleSizeChangeForICError" @current-change="handleCurrentChangeForICError"
                  :current-page.sync="currentPageForICError" :page-sizes="paginationPropsForICError"
                  :page-size="pageSizeForICError" layout="prev, pager, next, jumper, total"
                  :total="totalForICError"></el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <span slot="footer" v-if="activeStep == 0">
        <el-button @click="importFromSSActionModal = false">Cancel</el-button>
        <el-button type="danger" @click="activeStep = 1">Continue</el-button>
      </span>
      <span slot="footer" v-if="activeStep == 1 && uploadedCSVDataResult.length">
        <el-button @click="resetCSVData">Re-Upload CSV</el-button>
        <el-button type="danger" :disabled="successfullCSVData && !successfullCSVData.length"
          @click="importContacts">Import Contacts</el-button>>
      </span>
      <span slot="footer" v-if="activeStep == 2 && !isContactsCreated && !docSettingsVisible">
        <el-button @click="activeStep = 1">Back</el-button>
        <el-button type="danger" :loading="bulkSendBtnLoding" @click="checkSettingsVisibleOrNot">{{ `Send documents`
        }}</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import axios from "@/config/axios";
import { mapGetters } from "vuex";
import PermissionHelper from "@/mixins/permissionsHelper";

export default {
  name: "company-CompanyUsers",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  data() {
    return {
      client: null,
      userPrimaryDetailsForSS: [
        "first_name",
        "last_name",
        "email",
        "phone",
        "company_name",
        "title",
      ],
      activeStep: 0,
      order_type: null,
      order_by: null,
      importFromSSActionModal: false,
      activeNameForImportFromSS: "success",

      totalForIC: 0,
      selected_CompanyUser: [],
      isCompanyUserDelete: false,
      centerDialogVisible: false,

      currentPageForIC: 1,
      pageSizeForIC: 5,
      totalForICError: 0,
      currentPageForICError: 1,
      pageSizeForICError: 5,
      filter_user_type: "",
      // filtered_data: [],
      activeNameForImport: "success",
      state: "close",
      searchedContacts: [],
      searchedContact: " ",
      contactUsers: [],
      currentActiveRowIndex: -1,
      currentActiveSuccessRowIndex: -1,
      primaryHeaders: [],
      uploadedCSVDataResult: [],
      successfullCSVData: [],
      errorfullCSVData: [],
      showImportContactsPopup: false,

      importTotalContactsCnt: 0,
      importAddedContactsCnt: 0,
      importUpdatedContactsCnt: 0,
      importFailedContactsCnt: 0,
      importErrorDetails: [],
      viewImportingContactsResultPopup: false,
      allContacts: [],
      loading: false,
      loadingText: "Loading...",
      modalLoading: false,
      isVisible: true,
      documentActionModal: false,
      editDailogVisible: false,
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      searchedContactForSend: " ",
      search_string: " ",
      sort: " ",
      isContactsCreated: false,
      getBulkSendPercentage: 0,
      updatePersonalInformation: {
        first_name: "",
        last_name: "",
      },
      signup: {
        plan_type: "BUSINESS",
        email_confirmed: false,
        phone_confirmed: false,
        status: "ACTIVE",
        paid_user: false,
        account_status: true,
        company_id: undefined,
        password: "",
        cPassword: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        terms_and_conditions: true,
        user_type: {},
      },
      password_rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{6,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required", regex: /[!@#$%^&*]/ },
      ],
      rules: {
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Invalid Email",
            trigger: "blur",
          },
        ],
        // user_type: [
        //   {
        //     required: true,
        //     message: "User Type is required",
        //     trigger: "change",
        //   }
        // ],
        user_type_id: [
          {
            required: true,
            message: "User Type is required",
          },
        ],
        password: [
          {
            required: true,
            message: "Password is required",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            // pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
            pattern:/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
            message: "Invalid Phone Number",
          },
        ],
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last name is required",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "Company name is required",
            trigger: "blur",
          },
        ],
        terms_and_conditions: [
          {
            required: true,
            message: "Terms & conditions are need to be accept",
            trigger: "blur",
          },
        ],
        // cPassword: [
        //   {
        //     required: true,
        //     message: "Confirm Password is required",
        //     trigger: "blur",
        //   },
        // ],
      },
      passwordType: "password",
      cpasswordType: "password",
      passwordError: [],
      users: [],
      userType: [],
      dLoading: false,
      isEdit: false,
      userInfo: {},
      brandingInfo: {},
      isNotValidEmail: false,
      workspaceIndex: -1,
      userIndex: -1,
    };
  },
  computed: {
    getCurrentPageOfErrorfullCSVData() {
      const from = (this.currentPageForICError - 1) * this.pageSizeForICError;
      let to = from + this.pageSizeForICError;
      if (to > this.totalForICError) {
        to = this.totalForICError;
      }

      return this.errorfullCSVData.slice(from, to);
    },
    paginationPropsForICError() {
      return [5, 10, 20, 50];
    },
    getCurrentPageOfSuccessfullCSVData() {
      const from = (this.currentPageForIC - 1) * this.pageSizeForIC;
      let to = from + this.pageSizeForIC;
      if (to > this.totalForIC) {
        to = this.totalForIC;
      }
      return this.successfullCSVData.slice(from, to);
    },
    paginationPropsForIC() {
      return [5, 10, 20, 50];
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },

    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUpdateProfileStatus",
      "getUpdateProfileErrors",
      "getAddCompanyUser",
      "getCompanyUsers",
      "getGeneralErrorMessage",
      "getUserTypeList",
      "getActiveWorkspace",
      "getResendVerificationEmail",
      "getUserType",
      "getPermanentDeleteUserStatus"
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [5, 10, 20, 50],
      };
    },
    isImage() {
      return (type) => (type && type.indexOf("image") > -1 ? true : false);
    },
    isPdf() {
      return (type) => (type && type.indexOf("pdf") > -1 ? true : false);
    },
  },
  mixins: [PermissionHelper],

  created() {
    this.userInfo = this.getAuthenticatedUser;
  },
  async mounted() {
    if (this.getActiveWorkspace.plan_type == "INDIVIDUAL") {
      this.$router.push({
        path: "/dashboard",
      });
    }
    this.$store.commit("auth/setUpdateProfileErrors", null, { root: true });
    if (this.getActiveWorkspace.plan_type == "BUSINESS") {
      this.getCompanyUsersList();
      this.getCompanyUserTypeList();
      this.getCompanyInfo();
      
    }
  },
  methods: {
    getInvitedAt(scopeData) {
      if (this.getActiveWorkspace?.company_id && scopeData?.workspace) {
          let invitedUserData = scopeData.workspace.find(workspace => {
              return workspace && workspace.company_id && workspace.company_id._id && workspace.company_id._id === this.getActiveWorkspace.company_id;
          });
          return invitedUserData.created_at;
      } else {
          return "-";
      }
    },
    getColor(userType) {
     
      // let colors=['success','info','danger', 'warning']
      if (userType && userType.name === "OWNER") {
        return "danger";
      } else if (userType && userType.name === "ADMIN") {
        return "warning";
      } else if (userType && userType.name === "MANAGER") {
        return "info";
      } else if (userType && userType.name === "MEMBER") {
        return "success";
      }
      return "";
    },
    async bulkdelete() {
      console.log(this.selected_CompanyUser)
      if(this.selected_CompanyUser.length)
      {
      let userData= await this.selected_CompanyUser.map(async (data)=>{
        let info= await data.rolesList.map(async(role)=>{
          return {
            ...role.userType,email:data.email,email_id:data._id
          }
        })
        return await Promise.all(info)
      });
      let totalUsers=[]
      let users=await Promise.all(userData);
        await users.map(async(user)=>{
          // console.log("user",user);
          totalUsers=[...totalUsers,...user];
          return user
        })
        // console.log("Users",await Promise.all(data),totalUsers)
      let ownerRole = this.lodash.filter(
        totalUsers,
            function (element) {
              return element.name === "OWNER";
            }
          );
        if(ownerRole && ownerRole.length)
        {
          this.$message({
            showClose: true,
            message:
              "You do not have permission to delete the Application Owner.",
            type: "warning",
          });
        }
        else{
        this.centerDialogVisible = true;
        }
      }
      else{
        this.$message({
            showClose: true,
            message:
              "You have to select at least 1 user.",
            type: "warning",
          });
      }
      
    },
    async selectedCompanyUserDelete() {
      this.centerDialogVisible = false;
      this.isCompanyUserDelete = true;
      this.selected_CompanyUser.forEach(async (e, index) => {
        if (
          e && e._id
        ) {
          this.configureLoading = true;
          this.LoadingText = "Deleting Company User"
          await this.$store.dispatch("auth/deleteCompanyUser", e._id);

          if (index == this.selected_CompanyUser.length - 1) {
            this.$notify.success({
              title: "Success",
              message: "Company User deleted successfully",
            });
          }
          this.getCompanyUsersList()
          this.configureLoading = false;
        } else {
          this.$message({
            showClose: true,
            message:
              "You can only delete the documents which are created by you.",
            type: "warning",
          });
        }
      });
    },
    getContactTypeIdFromValue(value) {
      let Id = "";
      try {
        if (this.userType) {
          this.userType.forEach((type) => {
            if (type.name == value) {
              Id = type._id;
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      if (Id == "") {
        try {
          if (this.userType) {
            this.userType.forEach((type) => {
              if (type.name == value) {
                Id = type._id;
              }
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
      return Id;
    },
    updatedDateSort: function (a, b) {
      if (a.updated_at < b.updated_at) return -1;
      if (a.updated_at > b.updated_at) return 1;
      return 0;
    },
    async handleSelectionChange(val) {
      this.selected_CompanyUser = [];
      this.selected_CompanyUser = val;
    },
    async separateSuccessAndErrors() {
      this.uploadedCSVDataResult.forEach((row) => {
        let flag = true;
        var keys = Object.keys(row);
        keys.forEach((key) => {
          if (key == "first_name" || key == "last_name") {
            if (!row[key] || (row[key] && row[key].length < 3)) {
              flag = false;
            }
          } else if (key == "email") {
            if (
              !row[key] ||
              (row[key] &&
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  row[key]
                ))
            ) {
              flag = false;
            }
          }
        });
        if (flag) {
          this.successfullCSVData.push(row);
        } else {
          this.errorfullCSVData.push(row);
        }
      });

      if (this.errorfullCSVData && this.errorfullCSVData.length) {
        this.rearrangeTheErrorCSVData();
      }
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    async divideString(e) {
      let dividedString = e.split(/\r?\n/);
      if (dividedString && dividedString.length <= 1) {
        this.$message.error("Oops, Uploaded CSV file is empty.");
      } else {
        const headersTmp = dividedString[0].split(",");
        // console.log(">>> divideString(), headersTmp", headersTmp);

        let headers = [];
        let headersPos = [];
        headersTmp.forEach((item, index) => {
          let itemTmp = item.trim().toLowerCase();
          if (
            itemTmp == "first_name" ||
            itemTmp == "first name" ||
            itemTmp == "firstname"
          ) {
            headers.push("first_name");
            headersPos.push(index);
          } else if (
            itemTmp == "last_name" ||
            itemTmp == "last name" ||
            itemTmp == "lastname"
          ) {
            headers.push("last_name");
            headersPos.push(index);
          } else if (
            itemTmp == "email" ||
            itemTmp == "email address" ||
            itemTmp == "email_address" ||
            itemTmp == "emailaddress"
          ) {
            headers.push("email");
            headersPos.push(index);
          } else if (
            itemTmp == "phone number" ||
            itemTmp == "phonenumber" ||
            itemTmp == "phone" ||
            itemTmp == "cell"
          ) {
            headers.push("phone");
            headersPos.push(index);
          } else if (
            itemTmp == "company_name" ||
            itemTmp == "company name" ||
            itemTmp == "company_name" ||
            itemTmp == "company"
          ) {
            headers.push("company_name");
            headersPos.push(index);
          }
        });

        // console.log(
        //   ">>> divideString(), headers, headersPos",
        //   headers,
        //   headersPos
        // );

        let differentFields = this.userPrimaryDetailsForSS.filter(
          (x) => headers.indexOf(x) === -1
        );
        // console.log(
        //   ">>> divideString(), userPrimaryDetailsForSS",
        //   this.userPrimaryDetailsForSS
        // );
        // console.log(">>> divideString(), e", e);
        // console.log(">>> divideString(), dividedString", dividedString);
        // console.log(">>> divideString(), differentFields", differentFields);
        if (
          differentFields &&
          differentFields.length &&
          differentFields.length >= this.userPrimaryDetailsForSS.length
        ) {
          this.$message.error(
            "Oops, Uploaded CSV file has no matched columns with required CSV."
          );
        } else {
          this.isCSVUploaded = true;
          this.uploadedCSVDataResult = [];
          for (let i = 1; i < dividedString.length; i++) {
            let obj = {};
            let str = dividedString[i];
            if (dividedString[i] && dividedString[i].length) {
              let properties = str.split(",");
              // console.log(">>> divideString(), properties", properties);
              this.userPrimaryDetailsForSS.forEach((h) => {
                obj[h] = "";
              });
              for (let k in headers) {
                // console.log(
                //   ">>> divideString(), k, headersPos[k]",
                //   k,
                //   headersPos[k]
                // );
                // console.log(
                //   ">>> divideString(), properties[headersPos[k]]",
                //   properties[headersPos[k]]
                // );
                if (properties[headersPos[k]] != "") {
                  const j = headersPos[k];
                  if (properties[j] && properties[j].includes(",")) {
                    obj[headers[k]] = properties[j]
                      .split(",")
                      .map((item) => item.trim());
                  } else {
                    obj[headers[k]] = properties[j];
                  }
                  // console.log(
                  //   ">>> divideString(), obj, k, headers[k], headersPos[k], properties[j]",
                  //   obj,
                  //   k,
                  //   headers[k],
                  //   headersPos[k],
                  //   properties[j]
                  // );
                }
              }

              if (this.filter_user_type == "") {
                obj.user_type = "Receiver";
              } else {
                obj.user_type = this.filter_user_type;
              }
              this.uploadedCSVDataResult.push(obj);
            }
          }
        }

        this.primaryHeaders.push(
          "user_type",
          "first_name",
          "last_name",
          "email",
          "phone"
        );

        this.separateSuccessAndErrors();
      }
      // let result = [];
    },
    async sortChange(a) {
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      this.getCompanyUsersList();
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        this.getCompanyUsersList();
      }
    },
    uploadBulkSendCSV(file) {
      if (
        file.raw.type == "application/vnd.ms-excel" ||
        file.raw.type == "text/csv"
      ) {
        this.uploadedCSVFile = file.raw.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.divideString(e.target.result);
        };
        reader.readAsText(file.raw);
      } else {
        this.$message.error("Oops, Please upload a .CSV file.");
      }
    },
    generateCSVFile() {
      let primaryHeads = [];
      primaryHeads.push(
        "first_name",
        "last_name",
        "email",
        "phone",
        "company_name",
        "user_type"
      );

      if (!this.isGenerateCSVFile) {
        this.downloadCSVFile(primaryHeads.join(","));
      }
      this.isGenerateCSVFile = false;
    },
    downloadCSVFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `importFromSpreadsheet.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleSizeChangeForIC(val) {
      this.pageSizeForIC = val;
    },
    handleCurrentChangeForIC(val) {
      this.currentPageForIC = val;
    },
    handleCurrentChangeForICError(val) {
      this.currentPageForICError = val;
    },
    handleScroll() {
      let scrollbarEl = this.$refs.import_contacts_el_scroll_body;
      scrollbarEl.onscroll = () => {
        if (scrollbarEl.scrollTop > 135) {
          this.isFixedTop = true;
        } else {
          this.isFixedTop = false;
        }
      };
    },
    async onChangeFilterContactType() {
      await this.getCompanyUsersList();
      if (this.filter_user_type == "") {
        this.data = this.getCompanyUsers.data;
        this.total = this.filtered_data.length;
      }
      if (this.search_string == "") {
        this.data = this.getCompanyUsers.data;
      } else {
        this.data = this.getCompanyUsers.data.filter(
          (element) =>
            element.user_type.name == this.filter_user_type ||
            element.user_type.name == this.search_string
        );
        this.total = this.filtered_data.length;
      }
      this.getCompanyUsersList();
    },
    async onSearchContactType() {
      await this.getCompanyUsersList();
      if (this.search_string == "") {
        this.data = this.getCompanyUsers.data;
        this.total = this.filtered_data.length;
      } else {
        this.data = this.getCompanyUsers.data.filter(
          (element) => element.user_type.name == this.search_string
        );
        // this.total = this.filtered_data.length;
      }
    },
    async importContacts() {
      this.showImportContactsPopup = false;
      this.importFromSSActionModal = false;
      this.loadingText = "Importing Contacts...";
      this.loading = true;

      await this.fetchAllContacts();
      const totalOld = this.total;

      this.importTotalContactsCnt = this.successfullCSVData.length;
      this.importAddedContactsCnt = 0;
      this.importUpdatedContactsCnt = 0;
      this.importFailedContactsCnt = 0;
      this.importErrorDetails = [];

      for (let i = 0; i < this.successfullCSVData.length; i++) {
        var person = this.successfullCSVData[i];
        const resultStr = await this.addContact(person);
        if (resultStr !== "") {
          this.importErrorDetails.push(resultStr);
        }
      }
      this.loading = false;

      await this.getCompanyUsersList();

      this.importAddedContactsCnt = this.total - totalOld;
      this.importFailedContactsCnt =
        this.importTotalContactsCnt -
        this.importAddedContactsCnt -
        this.importUpdatedContactsCnt;
      this.viewImportingContactsResultPopup = true;
    },
    editSuccessCSVData(row) {
      this.currentActiveSuccessRowIndex = row.id;
    },
    resetSuccessCSVData(row, column) {
      if (column && column.label == "user_type") {
        return;
      }
      this.currentActiveSuccessRowIndex = -1;
    },
    editErrorCSVData(row) {
      this.currentActiveRowIndex = row.id;
    },
    /*async getCompanyUsersList(){
      try{
        this.configureLoading = true
      }catch(err){
        this.configureLoading = false
      }
    },*/
    resetErrorCSVData(row, column) {
      if (column && column.label == "user_type") {
        return;
      }
      let checkError = this.checkErrorCount();
      if (checkError) {
        this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);
        this.rearrangeTheErrorCSVData();
      }
      this.currentActiveRowIndex = -1;
      // this.errorfullCSVData.splice(this.currentActiveRowIndex, 1);

      this.totalForIC = this.successfullCSVData.length;
      this.totalForICError = this.errorfullCSVData.length;
    },
    rearrangeTheErrorCSVData() {
      this.errorfullCSVData.map((row, index) => {
        row["id"] = index;
      });
      this.successfullCSVData.map((row, index) => {
        row["id"] = index;
      });
    },
    checkErrorCount() {
      var keys = Object.keys(this.errorfullCSVData[this.currentActiveRowIndex]);
      let flag = true;

      keys.forEach((key) => {
        // console.log('>>> checkErrorCount(), key, this.errorfullCSVData[this.currentActiveRowIndex][key]', key, this.errorfullCSVData[this.currentActiveRowIndex][key]);
        if (key == "first_name" || key == "last_name") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              this.errorfullCSVData[this.currentActiveRowIndex][key].length < 3)
          ) {
            flag = false;
          }
        } else if (key == "email") {
          if (
            !this.errorfullCSVData[this.currentActiveRowIndex][key] ||
            (this.errorfullCSVData[this.currentActiveRowIndex][key] &&
              !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                this.errorfullCSVData[this.currentActiveRowIndex][key]
              ))
          ) {
            flag = false;
          }
        }
      });
      if (flag) {
        this.successfullCSVData.push(
          this.errorfullCSVData[this.currentActiveRowIndex]
        );
      }
      return flag;
    },
    resetData() {
      this.documentActionModal = false;
      this.resetForm();
      this.getCompanyUsersList();
    },
    resetBulkModal() {
      if (!this.isBulkSendIsProccessing) {
        this.resetCSVData();
        this.selectedCofigurable = null;
        this.selectedCompanyDocument = null;
        this.activeHeadingNames = [];
        this.getBulkSendPercentage = 0;
        this.isContactsCreated = false;
        this.contactLoading = false;
        this.importFromSSActionModal = false;
        this.isGenerateCSVFile = false;
        this.primaryHeaders = [];
        this.currentActiveSuccessRowIndex = -1;
        this.currentActiveRowIndex = -1;
        this.uploadedCSVFile = "";
        this.docSettingsVisible = false;
      }
    },
    resetCSVData() {
      this.successfullCSVData = [];
      this.errorfullCSVData = [];
      this.uploadedCSVDataResult = [];
      this.totalForIC = 0;
      this.totalForICError = 0;
      this.activeStep = 0;
    },
    format(percentage) {
      let per = percentage === 100 ? "" : `` || percentage != 100 ? "" : ``;
      console.log(per);
      let doc = "";
      return doc;
    },

    async separateSuccessAndErrorsFromGmail(connections) {
      this.primaryHeaders = [];
      this.primaryHeaders.push("user_type");
      this.primaryHeaders.push("first_name");
      this.primaryHeaders.push("last_name");
      this.primaryHeaders.push("email");
      this.primaryHeaders.push("phone");
      this.primaryHeaders.push("company_name");

      this.uploadedCSVDataResult = [];
      this.successfullCSVData = [];
      this.errorfullCSVData = [];

      for (let i = 0; i < connections.length; i++) {
        var aContact = connections[i];
        var aContactForRow = {};

        try {
          if (this.filter_user_type == "") {
            aContactForRow.user_type = "Receiver";
          } else {
            aContactForRow.user_type = this.filter_user_type;
          }

          if (aContact.names && aContact.names.length > 0) {
            // first_name
            if (aContact.names[0].givenName) {
              aContactForRow.first_name = aContact.names[0].givenName;
            } else {
              aContactForRow.first_name = aContact.names[0].displayName;
            }

            // last_name
            if (aContact.names[0].familyName) {
              aContactForRow.last_name = aContact.names[0].familyName;
            } else {
              aContactForRow.last_name = "";
            }
          }

          // email
          if (aContact.emailAddresses && aContact.emailAddresses.length > 0) {
            if (aContact.emailAddresses[0].value) {
              aContactForRow.email = aContact.emailAddresses[0].value;
            }
          } else {
            aContactForRow.email = "";
          }

          // phone
          if (aContact.phoneNumbers && aContact.phoneNumbers.length > 0) {
            if (aContact.phoneNumbers[0].value) {
              aContactForRow.phone = aContact.phoneNumbers[0].value;
              // contactFormData.phone = '';
            }
          } else {
            aContactForRow.phone = "";
          }

          // company_name
          aContactForRow.company_name = "";
          aContactForRow.title = "";
          if (aContact.organizations && aContact.organizations.length > 0) {
            if (aContact.organizations[0].name) {
              aContactForRow.company_name = aContact.organizations[0].name;
            }
            if (aContact.organizations[0].title) {
              aContactForRow.title = aContact.organizations[0].title;
            }
          }
          this.uploadedCSVDataResult.push(aContactForRow);
        } catch (err) {
          console.log(
            ">>> separateSuccessAndErrorsFromGmail() parsing error.",
            err
          );
        }
      }
      this.separateSuccessAndErrors();
    },

    async fetchAllContacts() {
      try {
        // this.loading = true;
        let params = { get_all: true };
        let response = await axios.get("/contacts", { params });
        this.allContacts = response.data.data;
        // this.loading = false;
      } catch (err) {
        // this.loading = false;
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.getCompanyUsersList();
      }
    },
    async goToImportGmailContact() {
      try {
        await this.$google.api.auth2.getAuthInstance().signIn();
        this.listConnectionNames();
      } catch (e) {
        console.log(e);
      }
    },
    async goToImportSpreadsheetContact() {
      this.resetCSVData();
      this.importFromSSActionModal = true;
    },
    async goToImportOutlookContact() {
      let contacts = await this.client
        .api("/me/contactFolders/{id}/contacts")
        .get();

      console.log(">>> contacts: ", contacts);
    },
    buildCommaSeparatedString(arr) {
      let rtn = "";
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== undefined && arr[i].trim().length > 0) {
          if (rtn.length > 0) {
            rtn += ", ";
          }
          rtn += arr[i].trim();
        }
      }
      return rtn;
    },

    getContactIdFromEmail(email) {
      for (let i = 0; i < this.allContacts.length; i++) {
        if (
          this.allContacts[i].email.toLowerCase().indexOf(email.toLowerCase()) >
          -1
        ) {
          return this.allContacts[i]._id;
        }
      }
      return "";
    },
    async listConnectionNames() {
      const self = this;
      this.$google.api.client.people.people.connections
        .list({
          resourceName: "people/me",
          // 'pageSize': 10,
          // 'personFields': 'names,emailAddresses',
          personFields:
            "addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined",
        })
        .then(async function (response) {
          var connections = response.result.connections;
          // console.log("Connections:", connections);
          if (connections.length > 0) {
            self.separateSuccessAndErrorsFromGmail(connections);

            self.loading = true;
            self.loadingText = "Loading Contacts...";
            await self.fetchAllContacts();
            self.loading = false;
            self.showImportContactsPopup = true;
          } else {
            // console.log('No contacts imported.');
          }
        });
    },
    openDialog() {
      this.resetForm();
      this.getCompanyUserTypeList();
      this.signup = {
        plan_type: "BUSINESS",
        email_confirmed: false,
        phone_confirmed: false,
        status: "ACTIVE",
        paid_user: false,
        account_status: true,
        company_id: undefined,
        password: "",
        cPassword: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        terms_and_conditions: true,
        user_type: {},
        user_type_id: "",
        assign_roles: [],
        selectedRoles: []
      };
      this.documentActionModal = true;
      this.$set(this.signup, "company_id", this.getActiveWorkspace.company_id);
      this.$set(this.signup, "paid_user", this.userInfo.paid_user);
      this.$set(this.signup, "account_status", this.userInfo.account_status);
      this.$set(
        this.signup,
        "terms_and_conditions",
        this.userInfo.terms_and_conditions
      );
      this.$set(
        this.signup,
        "email_confirmed",
        this.getActiveWorkspace.email_confirmed
      );
    },
    closeDialog() {
      this.documentActionModal = false;
      this.isNotValidEmail = false;
      this.resetForm();
      this.getCompanyUsersList();
    },
    submitForm() {
      this.dLoading = true;
      if (this.signup.selectedRoles && this.signup.selectedRoles.length) {
        this.$refs.companyUser.validate((valid) => {
          // console.log("valid", valid);
          if (valid) {
            if (!this.passwordError.length) {
              if (this.isEdit) {
                const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
                if (re.test(this.signup.phone)) {
                  this.updateUser();
                }
                else {
                  this.$message.error('Invalid phone number')
                }
              } else {
                this.signUp();
              }
            }
          } else {
            this.dLoading = false;
            return false;
          }
        });
      } else {
        this.$message.warning("Please Choose at least one Role");
      }
      this.dLoading = false;
    },
    resetForm() {
      if (this.isEdit) {
        this.isEdit = false;
      }

      if (this.$refs.companyUser) {
        this.$refs.companyUser.resetFields();
      }
    },
    async updateUser() {
      this.isEdit = true;
      try {
        this.loading = true;
        this.dLoading = true;
        let user = this.data[this.userIndex];
        let userWorkspace = user.workspace;
        this.signup.user_type_id = this.signup.selectedRoles && this.signup.selectedRoles.length ? this.signup.selectedRoles[0] : "";
        userWorkspace[this.workspaceIndex]["user_type"] = this.signup.user_type_id;
        let data = {
          _id: user._id,
          email: user.email,
          first_name: this.signup.first_name,
          last_name: this.signup.last_name,
          phone: this.signup.phone,
          workspace: userWorkspace,
          assign_roles: [],
        };

        let assignedRoles = await this.signup.selectedRoles.map(
          async (userRole) => {
            let userType = this.userType.find((e) => e._id == userRole);
            return {
              role_id: userType.role._id,
              userType: userType._id,
            };
          }
        );
        data.assign_roles = await Promise.all(assignedRoles);
        this.signup.assign_roles = data.assign_roles
        // delete data.cPassword;
        await this.$store.dispatch("auth/updateCompanyUser", data);
        if (this.getAddCompanyUser) {
          if (
            this.getAddCompanyUser.success ||
            this.getAddCompanyUser.success == "true"
          ) {
            this.$notify.success({
              title: "Success",
              message: "User Information updated successfully",
            });
            this.documentActionModal = false;
            this.resetForm();
            this.getCompanyUsersList();
          } else {
            this.$notify.error({
              title: "Error",
              message: this.getAddCompanyUser.message,
            });
          }
        }
        this.dLoading = false;
        this.loading = false;
      } catch (err) {
        this.dLoading = false;
        this.loading = false;
        // console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    checkEmailData(email) {
      if (email && email == this.getAuthenticatedUser.email) {
        return false;
      }
      return true;
    },
    async signUp() {
      try {
        this.loading = true;
        this.dLoading = true;
        let data = this.signup;
        data.email = this.signup.email.toLowerCase();
        let valid = this.checkEmailData(this.signup.email);
        data.user_type_id = data.selectedRoles && data.selectedRoles.length ? data.selectedRoles[0] : "";
        if (valid) {
          let selectedUserType = this.userType.find(
            (e) => e._id == data.user_type_id
          );
          data.user_type = selectedUserType;

          console.log("data.assign_roles", data.assign_roles);
          let assignedRoles = await data.selectedRoles.map(async (userRole) => {
            console.log("role", userRole);
            let userType = this.userType.find((e) => e._id == userRole);
            return {
              role_id: userType.role && userType.role._id,
              userType: userType._id,
            };
          });
          data.assign_roles = await Promise.all(assignedRoles);

          // console.log(data);
          // delete data.cPassword;
          await this.$store.dispatch("auth/inviteCompanyUser", data);
          if (this.getAddCompanyUser) {
            if (
              this.getAddCompanyUser.success ||
              this.getAddCompanyUser.success == "true"
            ) {
              this.$notify.success({
                title: "Success",
                message: "Invite successfully sent to user",
              });
              this.documentActionModal = false;
              this.resetForm();
              this.getCompanyUsersList();
            } else {
              // assignedRoles = await data.assign_roles.map(async (userRole) => {
              //   return userRole.userType;
              // });
              // this.signup.assign_roles = await Promise.all(assignedRoles);
              this.signup.assign_roles = []
              this.$notify.error({
                title: "Error",
                message: this.getAddCompanyUser.message,
              });
            }
          }
          this.dLoading = false;
          this.loading = false;
        } else {
          this.$message.error("Oops, You entered your email.");
          this.dLoading = false;
          this.loading = false;
        }
      } catch (err) {
        this.dLoading = false;
        this.loading = false;
        console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    checkPassword() {
      this.passwordError = [];
      this.password_rules.forEach((rule) => {
        if (!rule.regex.test(this.signup.password)) {
          this.passwordError.push(rule.message);
        }
      });
      if (!this.passwordError.length && this.signup.cPassword.length) {
        if (this.signup.password != this.signup.cPassword) {
          this.passwordError.push("Password missmatch");
        } else {
          this.passwordError = [];
        }
      }
    },
    async getCompanyUsersList() {
      try {
        let params = {
          limit: this.pageSize > 0 ? this.pageSize : 5,
          page: this.currentPage,
        };
        let user_type = "";
        try {
          user_type = this.filter_user_type;
        } catch (err) {
          console.log(err);
        }
        if (user_type != "") {
          params.user_type = user_type;
        }
        try {
          user_type = this.getContactTypeIdFromValue(this.search_string);
        } catch (err) {
          console.log(err);
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        this.loading = true;
        await this.$store.dispatch("auth/getCompanyUsersList", params);
        if (this.getCompanyUsers && this.getCompanyUsers.data) {
          this.data = this.getCompanyUsers.data;
          this.data = this.data.map(e => {
            e.status = this.getStatus(e,"status");
            return e
          })
          this.total = this.getCompanyUsers.total;
        }
        this.loading = false;
      } catch (err) {
        // console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async onEdit(user, uIndex) {
      this.userIndex = uIndex;
      this.workspaceIndex = user.workspace.findIndex(
        (u) =>
          u.company_id &&
          u.company_id._id.toString() ==
          this.getActiveWorkspace.company_id.toString()
      );
      // console.log(user)
      let assign = [];
      if (user.rolesList && user.rolesList.length) {
        let roles = user.rolesList.map(async (role) => {
          return role.userType._id;
          // {
          //     role_id: role.role_id._id,
          //     userType: role.userType._id
          //   }
        });
        assign = await Promise.all(roles);
      }

      this.signup = {
        _id: user._id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        user_type: user.workspace[this.workspaceIndex]["user_type"],
        user_type_id: user.workspace[this.workspaceIndex]["user_type"]["_id"],
        assign_roles: [],
        selectedRoles: assign
      };

      this.documentActionModal = true;
      this.isEdit = true;
    },
    async onView(user, uIndex) {
      this.userIndex = uIndex;
      this.workspaceIndex = user.workspace.findIndex(
        (u) =>
          u.company_id &&
          u.company_id._id.toString() ==
          this.getActiveWorkspace.company_id.toString()
      );

      let assign = [];
      if (user.rolesList && user.rolesList.length) {
        let roles = user.rolesList.map(async (role) => {
          return role.userType && role.userType._id;
        });
        assign = await Promise.all(roles);
      }
      this.signup = {
        _id: user._id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        user_type: user.workspace[this.workspaceIndex]["user_type"],
        assign_roles: assign,
        roles_list: user.rolesList,
      };
      this.documentActionModal = true;
    },
    async onDelete(id) {
      console.log(id)
      this.$confirm("Are you sure to delete the user?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("auth/deleteCompanyUser", id);
          if (this.getAddCompanyUser) {
            this.$notify.success({
              title: "Success",
              message: "User deleted successfully ",
            });
            this.getCompanyUsersList();
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async onPermanentDelete(id) {
      try {
        this.$confirm("Are you sure you want to permanently delete the user?")
          .then(async () => {
            this.loading = true;
            await this.$store.dispatch("auth/permanentDeleteCompanyUser", id);
            if (this.getPermanentDeleteUserStatus) {
              this.$notify.success({
                title: "Success",
                message: "User permanently deleted successfully."
              });
              this.getCompanyUsersList();
            }
            this.loading = false;
          })
      } catch (err) {
        console.log(err)
      }
    },
    async getCompanyUserTypeList() {
      try {
        await this.$store.dispatch(
          "auth/getUserTypeList",
         {companyId: this.getActiveWorkspace.company_id}
        );
        if (this.getUserTypeList) {
          this.userType = [];
          // this.userType = this.getUserTypeList;
          this.getUserTypeList.map((t) => {
            if (this.getUserType.name && this.getUserType.name == "OWNER") {
              this.userType.push(t);
            } else if (
              t &&
              t.name &&
              this.getUserType.name &&
              this.getUserType.name == "ADMIN" &&
              (this.getUserType.role._id === t.role._id || this.getAuthenticatedUser._id === t.role.created_by)
            ) {
              this.userType.push(t);
            } else if (
              t &&
              t.name &&
              this.getUserType.name &&
              this.getUserType.name == "MANAGER" &&
              (this.getUserType.role._id === t.role._id || this.getAuthenticatedUser._id === t.role.created_by) &&
              (t.name == "MANAGER" || t.name == "MEMBER")
            ) {
              this.userType.push(t);
            } else if (
              t &&
              t.name &&
              this.getUserType.name &&
              (this.getUserType.role._id === t.role._id || this.getAuthenticatedUser._id === t.role.created_by) &&
              this.getUserType.name == "MEMBER" &&
              t.name == "MEMBER"
            ) {
              this.userType.push(t);
            }
          });
        }
      } catch (err) {
        // console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = this.getCompanyDetails;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    verifyDomainUser() {
      if (this.brandingInfo.enforce_domain) {
        if (this.signup.email) {
          let domainName = this.signup.email.split("@")[1].toLowerCase();
          let companyDomain = this.brandingInfo.email_domain.toLowerCase();
          console.log("companyDomaincompanyDomain", companyDomain)
          if (companyDomain && companyDomain != domainName) {
            this.isNotValidEmail = true;
            this.$notify.error({
              title: "Error",
              message:
                "Only Users from " +
                this.brandingInfo.email_domain +
                " are allowed",
            });
          } else {
            this.isNotValidEmail = false;
          }
        }
      }
    },
    getData(scope, column) {
      let wsIndex = scope.row.workspace.findIndex(
        (e) =>
          e.company_id &&
          e.company_id._id.toString() ==
          this.getActiveWorkspace.company_id.toString()
      );
      if (wsIndex != -1) {
        if (
          column == "status" &&
          scope.row &&
          scope.row.workspace &&
          scope.row.workspace[wsIndex] &&
          scope.row.workspace[wsIndex][column] &&
          scope.row.workspace[wsIndex][column]
        ) {
          return "" + scope.row.workspace[wsIndex][column];
        }
        if (
          column == "user_type" &&
          scope.row &&
          scope.row.workspace &&
          scope.row.workspace[wsIndex] &&
          scope.row.workspace[wsIndex][column] &&
          scope.row.workspace[wsIndex][column]["name"]
        ) {
          let roleWithUserType = this.lodash.filter(
            this.getUserTypeList,
            function (element) {
              return element._id === scope.row.workspace[wsIndex][column]._id;
            }
          );
          // console.log("role", role);
          // let userTypeInfo = this.userType.find((t)=>(t._id && (t._id.toString() == scope.row.workspace[wsIndex][column].toString())))
          // return scope.row.workspace[wsIndex][column]["name"];
          if (
            roleWithUserType &&
            roleWithUserType.length &&
            roleWithUserType[0] &&
            roleWithUserType[0].role
          ) {
            return (
              roleWithUserType[0].role.title + " - " + roleWithUserType[0].name
            );
          } else {
            return scope.row.workspace[wsIndex][column]["name"];
          }
        }
      }
      return "NA";
    },
    checkStatus(scope) {
      // console.log(user)
      let wsIndex = scope.row.workspace.findIndex(
        (e) =>
          e.company_id &&
          e.company_id.toString() ==
          this.getActiveWorkspace.company_id.toString()
      );
      if (wsIndex != -1) {
        if (
          scope.row.workspace[wsIndex]["status"] == "PENDING" ||
          scope.row.workspace[wsIndex]["status"] == "HOLD" ||
          scope.row.workspace[wsIndex]["status"] == "INACTIVE"
        ) {
          return true;
        }
      }
      return false;
    },
    async resendVerificationMail(email) {
      try {
        this.loading = true;

        await this.$store.dispatch("auth/resendInviteEmailToUser", {
          email: email,
          company: this.getActiveWorkspace.company_id,
        });

        if (this.getResendVerificationEmail) {
          this.$notify.success({
            title: "Success",
            message: "Verification mail sent successfully.",
          });
          this.getCompanyUsersList();
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error at sending verification mail",
        });
      }
    },
    checkPermissions(permission) {
      if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    customSort(a, b) {
      const statusA = a.status.toUpperCase();
      const statusB = b.status.toUpperCase();

      if (statusA < statusB) {
        return -1;
      }
      if (statusA > statusB) {
        return 1;
      }
      return 0;
    },
    getStatus(scope, column) {
      let wsIndex = scope.workspace.findIndex(
        (e) =>
          e.company_id &&
          e.company_id._id.toString() ==
          this.getActiveWorkspace.company_id.toString()
      );
      if (wsIndex != -1) {
        if (
          column == "status" &&
          scope &&
          scope.workspace &&
          scope.workspace[wsIndex] &&
          scope.workspace[wsIndex][column] &&
          scope.workspace[wsIndex][column]
        ) {
          return "" + scope.workspace[wsIndex][column];
        }
      }
      return "NA";
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.getCompanyUsersList();
    },
    getIsMobile: function(){
        console.log("getIsMobile",this.getIsMobile);
        this.$router.go();
    }
  },
};
</script>

<style lang="scss">
.el-popover {
    min-width: 50px;
    padding: 8px;
    font-weight: 600;
}
.import-dropdown-btn {
  margin-left: 10px;
  height: 40px;

  .el-button {
    height: 100%;
    font-size: 14px;

    span {
      padding: 0 10px;
    }
  }
}

.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;

    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }

    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}

.importing-result-popup {
  .el-dialog__body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.el-table__empty-text {
  width: 100%;
}
</style>
<style lang="scss" scoped>
.el-tag+.el-tag {
  margin: 0.2rem;
}

.tab-success {
  .el-scrollbar__thumb {
    width: 20px;
  }
}

.tab-error {
  .el-scrollbar__thumb {
    width: 20px;
  }
}

.import-contacts-table {
  min-height: 350px;
}

.import-contacts-table-wrapper {
  display: inline-block;

  .el-table {
    display: inline-block;

    .el-table__body-wrapper {
      display: inline-block !important;
    }
  }
}

.top-search-condition-bar-type-two {
  @media (max-width: 991.98px) {
    display: flex !important;
    flex-direction: column !important;

    .actions-wrapper {
      display: flow-root !important;

      .import-contact {
        padding: 6px !important;
      }

      button {
        margin-top: 5px;
      }

      .filter-user-type {
        margin-top: 5px;
      }
    }

    h3 {
      width: 100%;
      margin-left: 10px;
    }
  }

  .actions-wrapper {
    .filter-user-type {
      margin-left: 10px;
      margin-right: 10px;
      width: 200px;
    }

    .import-contact {
      margin-left: 10px !important;
      padding: 6px 20px;
      height: 40px;
      width: 120px;
      // .icon-type-one {
      //   width: 25px;
      //   // height: 25px;
      //   &.gmail {
      //   }
      // }
      // .text {
      //   margin-left: 10px;
      //   margin-right: 23px;
      //   position: relative;
      //   top: -6px;
      // }
    }
  }
}

.indent {
  margin-left: 20px !important;
}

.error-details-title {
  margin-top: 20px !important;
  margin-bottom: 5px !important;
}

.error-details {
  color: red;
  font-style: italic;
}

.result-row {
  margin-left: 10px;
  margin-bottom: 15px;

  .larger-font-size {
    font-size: 15px;
  }

  &.failed {
    color: red;
  }
}

.mrg {
  margin-left: 55%;
  border-radius: 2px;
}

.ntg {
  background: #2f80ed;
  color: #ffffff !important;
  border-radius: 2px;
}

.text-bold {
  font-weight: 600;
}

.all-documents-view {
  .title {
    letter-spacing: 0.019em;
  }

  // .icon-block {
  .initial {
    grid-template-columns: 35px auto;

    .icon {
      border: 1px solid #ffa9a9;
      text-align: center;
      border-radius: 30px;
      padding: 5px;
      background-color: #f1494910;

      .initial-text {
        font-size: 0.8em;

        span {
          color: #f14949;
        }
      }
    }
  }

  // }
  body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // background-color: #F0AD4E;
  }

  label {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 5px 12px;
    transition: all 1s ease;
    border-radius: 0;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 80%;
      background-color: #f754a2;
      transition: all 1s ease 0.5s;
    }

    input {
      transition: width 1s ease, opacity 0.5s ease 0.5s;
      //opacity: 0.5;
      width: 180px;
      height: 18px;
      border: 0;
      outline: none;
    }

    i {
      position: absolute;
      top: 11px;
      right: 11px;
      border-color: #333;

      cursor: pointer;
    }

    &[data-state="close"] {
      border-radius: 0px;
      padding: 2px;
      transition: all 0.5s ease;

      &::after {
        width: 0%;
        transition: all 0.2s ease;
      }

      i {
        pointer-events: none;
      }

      input {
        width: 28px;
        height: 25px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.5s ease, width 1s ease;
        -webkit-appearance: none;
      }
    }
  }

  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;

    &::after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }

    &.completed::after {
      background: #08ad36;
    }

    &.draft::after {
      background: #c4c4c4;
    }

    &.viewed::after {
      background: #2f80ed;
    }
  }
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
  scrollbar-width: thin;
}
</style>
